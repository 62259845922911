<template>
    <ClicColInterno :cp="cp" :key="componentKey" :id="componentKey">
        <div>
            <b-link v-if="cp.props.visible" :href="cp.props.href" @click="click()">{{ cp.props.text }}</b-link>
        </div>
    </ClicColInterno>
</template>

<script>

import ComponentInternalMixin from '../../core/ComponentInternalMixin';

export default {
    name: 'ClicLink',
    mixins: [ComponentInternalMixin],
    created() {
    },
    mounted() {
        console.log(this.props)
    },
    methods: {
        click() {
            if (this.lodash.has(this.cp, 'events.click')) {
                try {
                    eval(this.cp.events.click);
                } catch (e) {
                    console.error(`[ClicLink] Falha ao executar evento 'click'. Detalhes: ${e}`);
                }
            }
        },

    }
}

</script>
