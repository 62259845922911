import ClicColInterno from '../clicGridLayout/ClicColInternoModel'
import ClicCodeReaderDocs from './ClicCodeReaderDocs';

export default {
    ClicCodeReader : {
        config      : {isContainer: false, icon:"md-calendarmonth-twotone", documentation: ClicCodeReaderDocs.documentation },
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' } },
        
        props : {
            scannerOptions  : { type: 'array',   default: undefined,        component: { name : 'multiselect', labels: "text", multiple: true, trackBy: "id", values: ["BARCODE", "QRCODE"] } },
            barCodeTypes    : { type: 'array',   default: undefined,               component: { name : 'multiselect', labels: "text", multiple: true, trackBy: "id", values: [ "code_128_reader", "ean_reader", "ean_8_reader", "code_39_reader", "code_39_vin_reader", "codabar_reader", "upc_reader", "upc_e_reader", "i2of5_reader", "2of5_reader", "code_93_reader"] } },
            ...ClicColInterno,

        },
        events : {
            onCodeScan       : {type: 'string', component: { name : 'code' } }
        }
    }
}









