import ClicColInterno from '../clicGridLayout/ClicColInternoModel';

import ClicImageDocs from './ClicImageDocs';

export default {
    ClicImage : {
        config      : {icon: "md-image", documentation: ClicImageDocs.documentation},
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            
            title       : {type: 'string',      default: undefined,  component: { name : 'input' } },
            alt         : {type: 'string',      default: undefined,  component: { name : 'input' } },
            blank       : {type: 'boolean',     default: undefined,  component: { name : 'switch' } },
            blankColor  : {type: 'string',      default: undefined,  component: { name : 'input' } },
            block       : {type: 'boolean',     default: undefined,  component: { name : 'switch' } },
            center      : {type: 'boolean',     default: undefined,  component: { name : 'switch' } },
            fluid       : {type: 'boolean',     default: undefined,  component: { name : 'switch' } },
            fluidGrow   : {type: 'boolean',     default: undefined,  component: { name : 'switch' } },
            height      : {type: 'multiple',    default: undefined,  component: { name : 'input' } },
            left        : {type: 'boolean',     default: undefined,  component: { name : 'switch' } },
            right       : {type: 'boolean',     default: undefined,  component: { name : 'switch' } },
            rounded     : {type: 'multiple',    default: undefined,  component: { name : 'input' } },
            sizes       : {type: 'multiple',    default: undefined,  component: { name : 'input' } },
            src         : {type: 'string',      default: undefined,  component: { name : 'input' } },
            fallbackSrc : {type: 'string',      default: undefined,  component: { name : 'input' } },
            srcset      : {type: 'multiple',    default: undefined,  component: { name : 'input' } },
            thumbnail   : {type: 'boolean',     default: undefined,  component: { name : 'switch' } },
            width       : {type: 'multiple',    default: undefined,  component: { name : 'input' } },
            height      : {type: 'multiple',    default: undefined,  component: { name : 'input' } },

            ...ClicColInterno   

        },
        events : { 
        }
    }
}