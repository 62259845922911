<template>
  <ClicColInterno md="12" :cp="cp" :key="componentKey"
    containerStyle="
    height: 100%;
    width: 100%;">


  <b-row style="height: 100%;">

    <b-col
      v-for="chart in chartElements"
      :key="chart.chartId"
      :id="chart.chartId"
      :sm="chart.sm || ''"
      :md="chart.md || ''"
      :lg="chart.lg || ''"
      :xl="chart.xl || ''"
      class="p-1"
    >
    </b-col>
  </b-row>

  </ClicColInterno>
</template>

<script>

import ComponentMixin from '../../core/ComponentMixin';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import storage from '../../store/modules/storage';
import axios from 'axios';


export default {
  name: 'ClicMongoDBChart',
  mixins: [ComponentMixin],
  data() {
    return {

      public: {
        dataRefresh: this.dataRefresh
      },

      mongoDbChartId: this.$utils.getComponentID(),
      chartElements: [],
      renderCount: 0


    };
  },

  mounted() {
    this.obterMongoDashboard()
  },
  methods: {
    async obterMongoDashboard() {
      if (this.clicDashboardId) {
        const options = {
          method: 'GET',
          url: `${process.env.VUE_APP_BACKEND_URL}/api/mongodashboard`,
          headers: { "content-type": "application/json" },
          params: { subdominio: this.$host.subdominio, id: this.clicDashboardId }
        };

        try {
          const response = await axios.request(options);
          const clicDashboard = response.data.dados?.dashboards;
          let sortedDashboard = clicDashboard?.charts.sort((a, b) => a.position - b.position);

          console.log(sortedDashboard)
          if (clicDashboard && sortedDashboard) {
            sortedDashboard.forEach((chart) => {
              this.renderCount++;
              this.dataRefresh({ ...chart });
            });
          }

          console.log(`[ClicMongoDBChart] Total de gráficos carregados: ${this.renderCount}`);
        } catch (e) {
          console.error("[ClicMongoDBChart] Erro ao buscar o dashboard:", e);
        }
      }
    },



    dataRefresh(parametros) {
      if (!this.baseUrl || !parametros.chartId) {
        console.error("[ClicMongoDBChart] Parâmetros baseUrl ou chartId são obrigatórios.");
        return;
      }

      if (parametros.visible) {
        const chartId = `chart-${parametros.chartId}-${this.chartElements.length}`;
        this.chartElements.push({ ...parametros, chartId: chartId }); 

        this.$nextTick(() => {
          const sdk = new ChartsEmbedSDK({
            baseUrl: this.baseUrl,
            chartId: parametros.chartId,
            ...parametros
          });

          this.loadChart(sdk, chartId);
        });
      }
    },


    loadChart(sdk, chartId) {
      const chart = sdk.createChart({
        getUserToken: () => storage.getToken(),
        showAttribution: false
      });

      const chartElement = document.getElementById(chartId);

      if (chartElement) {
        chart.render(chartElement).catch((e) => {
          console.error(`[ClicMongoDBChart] Falha ao carregar 'chart'. Detalhes: ${e}`);
        });
      } else {
        console.error(`[ClicMongoDBChart] Elemento DOM com ID ${chartId} não encontrado.`);
      }
    }

    // loadDashboard(sdk, parametros) {
    //   const dashboard = sdk.createDashboard({
    //     getUserToken: () => {
    //       return storage.getToken()
    //     },
    //     showAttribution: false,
    //     widthMode: "scale",

    //     width: '100%',

    //     theme: "dark",
    //     background: "transparent",


    //     filter: {
    //       "situacao.descricao": { $in: ["Faturado", "Cancelado"] }
    //     },

    //     ...parametros
    //   });
    //   const chartElement = document.getElementById(this.mongoDbChartId);
    //   if (chartElement) {
    //     dashboard.render(chartElement).catch((e) => {
    //       console.error(`[ClicMongoDBChart] Falha ao carregar 'dashboard'. Detalhes: ${e}`);
    //     });
    //   } else {
    //     console.error(`[ClicMongoDBChart] Elemento DOM com ID ${this.mongoDbChartId} não encontrado.`);
    //   }
    // },


  },
};
</script>


<style>
.Charts_app-dashboard-shared_layout---mtgxT {
  padding: 0px !important;
}
</style>