import ClicLinkDocs from "./ClicLinkDocs"

export default {
    ClicLink : {
        config      : {isContainer: false, icon: "md-dynamicfeed-round", documentation: ClicLinkDocs.documentation},
        component   : {type: 'string', component: { name : '' }, disabled:true},
        id          : {type: 'string', component: { name : '' }},
        props : {
            text                    : { type: 'string',     default: undefined,     component: { name : 'input'  } },
            href                    : { type: 'string',     default: undefined,     component: { name : 'input'  } },
            disabled                : { type: 'boolean',    default: undefined,     component: { name : 'switch' } },
            visible                 : { type: 'boolean',    default: true,          component: { name : 'switch' } },
        },
        events : {
            click  : {type: 'string', component: { name : 'code' } },
        },
    }   
}