<template>
    <div v-if="estrutura">
        <div v-if="!properties" class="form-label toast-header header-title"> Geral
        </div>

        <b-row v-for="(prop, chave, index) in estrutura" :key="index" align-v="end">

            <b-col md="4" sm="4" v-if="prop && prop.type" class="text-left">

                <label class="form-label custom-label">{{ chave }}&nbsp;
                </label>

                <ClicHintInterno
                    v-if="(estrutura[chave] && estrutura[chave].documentation) || (estrutura.config && estrutura.config.documentation && estrutura.config.documentation[chave])"
                    :cp="{
        props: {
            ...getDocumentationByProp(estrutura, chave)
        }
    }" />
            </b-col>

            <b-col sm="12" md="8"
                v-if="prop && prop.component && (prop.component.name == 'input' || prop.component.name === '') && prop.type != 'boolean'">
                <div class="form-group">
                    <b-form-input v-if="prop && prop.type != 'number' && prop.type != 'integer'"
                        :placeholder="lodash.get(prop, 'default', '').toString()" class="form-control"
                        v-model="props[chave]" v-on:change="updateChange(chave, props[chave])"
                        :disabled="lodash.get(prop, 'disabled', false)" />

                    <b-form-input v-if="prop && prop.type == 'number' || prop.type == 'integer'"
                        :placeholder="lodash.get(prop, 'default', '').toString()" type="number" class="form-control"
                        v-model="props[chave]" v-on:change="updateChange(chave, props[chave])"
                        :disabled="lodash.get(prop, 'disabled', false)" />

                </div>
            </b-col>

            <b-col sm="8" v-if="prop && prop.component && prop.component.name == 'code'">
                <b-input-group>
                    <b-button v-b-modal="chave" variant="outline-primary"
                        class="btn-modal-code-editor">{{ estrutura[chave].value != undefined || props[chave] != undefined
        ? 'Editar' : 'Novo' }}
                    </b-button>

                </b-input-group>
                <CodeEditor :code="props[chave] != undefined ? props[chave] : estrutura[chave].value" :chave="chave"
                    :parentId="parentId"
                    v-on:changeValueCodeEditor="(newValue) => { updateChange(chave, newValue); props[chave] = newValue }" />
            </b-col>

            <b-col sm="8"
                v-if="prop && prop.component && (prop.component.name == 'select' || prop.component.name == 'multiselect')">
                <Multiselect cols="12" :value="stringToArrayFormat(props[chave])"
                    :placeholder="lodash.get(prop, 'default', '').toString()"
                    :options="lodash.get(prop, 'component.values', [])"
                    :multiple="prop.component.name == 'select' ? false : true"
                    v-on:changeValueMultiselect="(newValue) => props[chave] = arrayToStringFormat(newValue)" />
            </b-col>

            <b-col sm="8" v-if="prop && prop.type && prop.type == 'boolean'">
                <b-form-checkbox v-model="props[chave]" name="check-button"
                    v-on:change="updateChange(chave, props[chave])" switch
                    :disabled="lodash.get(prop, 'disabled', false)" />

            </b-col>

            <b-col sm="12" v-if="props && typeof props[chave] == 'object'">
                <div class="form-label toast-header header-title"> {{ chave }} </div>
                <Propriedades :parentId="parentId" :structure="prop" :properties="props[chave]" />
            </b-col>

            <b-col sm="12" v-if="props && typeof props[chave] == 'object' && prop.containerFW">
                <div class="form-label toast-header header-title"> Container </div>
                <Propriedades :parentId="parentId" :structure="prop.containerFW" :properties="props[chave]" />
            </b-col>

            <b-col sm="12" v-if="props && typeof props[chave] == 'object' && prop.iconFW">
                <div class="form-label toast-header header-title"> Ícone </div>
                <Propriedades :parentId="parentId" :structure="prop.iconFW" :properties="props[chave]" />
            </b-col>


            <b-col sm="12" v-if="props && typeof props[chave] == 'object' && prop.imageFW">
                <div class="form-label toast-header header-title"> Imagem </div>
                <Propriedades :parentId="parentId" :structure="prop.imageFW" :properties="props[chave]" />
            </b-col>

            <b-col sm="12" v-if="props && typeof props[chave] == 'object' && prop.hintFW">
                <div class="form-label toast-header header-title"> Hint </div>
                <Propriedades :parentId="parentId" :structure="prop.hintFW" :properties="props[chave]" />
            </b-col>

            <b-col sm="12" v-if="props && typeof props[chave] == 'object' && prop.badgeFW">
                <div class="form-label toast-header header-title"> Ícone </div>
                <Propriedades :parentId="parentId" :structure="prop.badgeFW" :properties="props[chave]" />
            </b-col>


        </b-row>
    </div>
</template>


<script>
import CodeEditor from './CodeEditor.vue'
import Multiselect from './Multiselect.vue'
export default {
    name: 'Propriedades',
    components: {
        CodeEditor,
        Multiselect
    },

    data() {
        return {
            estrutura: {},
            props: {},
        }
    },
    methods: {

        getDocumentationByProp(estrutura, chave) {
            try {
                if (this.lodash.has(estrutura, `[${chave}].documentation`)) {
                    return estrutura[chave].documentation
                } else if (this.lodash.has(estrutura, `config.documentation[${chave}]`)) {
                    return estrutura.config.documentation[chave]
                } else {
                    return {};
                }
            } catch (e) {
                console.error("Ocorreu um erro na função 'getDocumentationByProp' - Criador de telas. Detalhes:", e);
            }
        },


        arrayToStringFormat(array) {
            const string = array.toString().replace(",", " ");
            return string;
        },

        stringToArrayFormat(string) {
            if (string && string != "") {
                const array = string.split(" ");
                return array;
            } else {
                return [];
            }

        },

        populateStructure() {
            if (this.structure) {
                this.estrutura = this.structure;
            }

            if (this.estrutura && this.properties) {
                for (let key in this.estrutura) {
                    if (!this.properties[key] && this.properties[key] == undefined) {
                        if (this.estrutura[key] && this.estrutura[key].default && this.estrutura[key].default != undefined) {
                            this.properties[key] = this.estrutura[key].default
                        }
                    }

                }
            }

            if (this.properties) {
                for (let key in this.properties) {
                    if (typeof this.properties[key] == 'object') {
                        this.properties[key] = JSON.stringify(this.properties[key]);
                    }
                }

                this.props = this.properties;
            }
        },

        updateChange(chave, valor) {
            try {
                this.estrutura[chave].value = valor;
                this.$forceUpdate();
            } catch (e) {
                console.error("[Propriedades | Criador de telas] Ocorreu um erro na execução do método 'updateChange'", e);
            }

        },

        atualizarConsulta(estrutura, propriedades) {
            this.estrutura = this.clonarEstrutura(estrutura);
            this.props = propriedades;
            
        },
        
        clonarEstrutura(estruturaRecebida) {
            let estrutura = this.lodash.clone(estruturaRecebida);

            return estrutura;
        }
    },

    props: {
        structure: {
            type: Object
        },
        properties: {
            type: Object
        },
        parentId: {
            type: String
        },
    },

    mounted() {
        this.populateStructure();
    },

    updated() {
        this.populateStructure();
    }

}

</script>
<style scoped>
div {
    padding-top: 5px !important;
}

div .toast-header {
    text-transform: capitalize !important;
}

.btn-modal-code-editor {
    height: 35px;
    justify-content: center;
    width: 100px;
    margin-left: 5px;
    margin-top: -2px;
    padding: 0px
}

.header-title {
    text-align: center;
    font-weight: 900;
}

.custom-label {
    padding-left: 3vw;
    font-size: 13px;
    font-weight: 500 !important;
    color: rgb(116, 116, 116)
}
</style>
