<template>
    <div class="row">
        <b-col md="12">
            <b-tabs id="guias-criador-de-telas" nav-class="p-0 bg-custom" content-class="p-2">
                <template #tabs-start>

                    <b-col md="4">
                        <template v-if="getFromNavBar">
                            <div class="p-4" style="color: white">
                                <p>Obtendo Subdomínio da Navbar</p>
                            </div>
                        </template>
                        <template v-else>
                            <Multiselect class="p-2 pr-4" sm="12" md="12" :disabled="modalPropriedadesShow"
                                :value="subdominio" :placeholder="'Selecione o subdomínio'" :options="subdominios"
                                :multiple="false"
                                v-on:changeValueMultiselect="(newValue) => { selectSubdomain(newValue) }" />
                        </template>
                    </b-col>
                </template>
                <b-tab title="Estrutura" active>
                    <b-row>
                        <b-col ref="treesCol" sm="12" md="4" id="containerColunaEstrutra" class="containerViewHeight">
                            <b-card class="containerViewHeight" id="componentes" @dblclick="dbClickCounter++">
                                <template #header>
                                    <b-row class="header-card">
                                        <b-col md="10">
                                            <b>
                                                Componentes
                                            </b>
                                        </b-col>
                                    </b-row>
                                </template>
                                <TreeBrowser :nodes="treeDataComponents" :key="treeComponentKey"
                                    v-on:select="(node) => { onNodeSelectedComponents(node) }" :copyable="true"
                                    :isEditable="false" :searchable="true" />
                            </b-card>
                        </b-col>

                        <b-col md="8" sm="12" id="containerColunaEstrutra" class="containerViewHeight"
                            style="padding-left:10px">
                            <b-card class="containerViewHeight" @dblclick="dbClickCounter++">
                                <template #header>
                                    <b-row class="header-card">
                                        <b-col class="text-left" cols="12">
                                            <!-- <span id="btn-scroll-demo" @click="scrollToElement('containerDemo')">
                                                <v-icon 
                                                    name="md-preview-sharp"
                                                    scale="1.5"
                                                    :animation="rootChildrens.length == 0 ? 'ring' : 'float'"
                                                    speed="slow"
                                                    :hover="true"
                                                />
                                            </span> -->
                                            <span id="btn-salvar" @click="salvarEstruturaGeral" class="pl-3">
                                                <v-icon name="md-save-outlined" scale="1.5" animation="float"
                                                    speed="slow" :hover="true" />
                                            </span>
                                            <span id="btn-voltar" @click="atualizarConsulta" class="pl-3">
                                                <v-icon name="md-undo-round" scale="1.5" animation="float" speed="slow"
                                                    :hover="true" />
                                            </span>
                                            <b-tooltip target="btn-salvar" triggers="hover">
                                                {{ subdominio != "" ?
                            'Clique para salvar a estrutura atual.' :
                            'Selecione um subdomínio'
                                                }}
                                            </b-tooltip>
                                            <b-tooltip target="btn-voltar" triggers="hover">
                                                {{ subdominio ?
                            'Clique para reverter as alterações' :
                            'Selecione um subdomínio'
                                                }}
                                            </b-tooltip>
                                            <!-- <b-tooltip target="btn-scroll-demo" triggers="hover">
                                                {{ rootChildrens && rootChildrens.length > 0 ? 
                                                    'Clique para visualizar o preview da estrutura.':
                                                    'Selecione uma estrutura'
                                                }}
                                            </b-tooltip> -->
                                        </b-col>

                                    </b-row>
                                </template>

                                <TreeBrowser style="margin-top:2vh" :key="treePropertiesKey"
                                    v-if="treeDataProperties.length > 0 && subdominio != ''" :copyable="false"
                                    :nodes="treeDataProperties"
                                    v-on:select="(node, index) => { onNodeSelectedComponents(node, index) }" />

                                <div class="h-100 d-flex align-items-center justify-content-center"
                                    v-if="subdominio == ''">
                                    <p class="aviso-subdominio">
                                        Selecione um subdomínio
                                    </p>
                                </div>

                            </b-card>
                        </b-col>

                        <b-col md="6">
                            <b-card-group deck :class="modalPropriedadesShow
                            ? 'toggle-slide toggle-slide-right content-active'
                            : 'toggle-slide toggle-slide-right'">
                                <b-card header-tag="header" footer-tag="footer">
                                    <template #header>
                                        <h6>{{ 'Propriedades ' + tituloModalPropriedades }}</h6>
                                    </template>
                                    <b-col :key="containerPropriedadesKey" @keydown.esc="hideModal" tabindex="0"
                                        style="padding:0; height: 100%;" class="side-menu">
                                        <Propriedades ref="compPropriedades"  :parentId="parentIdPropriedades"/>
                                    </b-col>

                                    <template #footer>
                                        <b-row>
                                            <b-col lg="3" md="8" sm="12" class="container-modal-actions">
                                                <b-button variant="outline-primary" @click="hideModal">
                                                    OK
                                                </b-button>

                                                <b-button :disabled="isLoading" variant="outline-primary"
                                                    style="margin-left:10px" @click="salvarEstruturaGeral">
                                                    <template v-if="isLoading">
                                                        <b-spinner small></b-spinner>
                                                        <span class="sr-only"></span>
                                                    </template>

                                                    Salvar
                                                </b-button>


                                            </b-col>
                                        </b-row>
                                    </template>
                                </b-card>

                            </b-card-group>
                        </b-col>

                        <!-- <b-col 
                            cols="12"
                            class="containerDemonstracao"
                            v-if="rootChildrens !=''"
                            :key="containerDemoKey"
                            ref="containerDemo"
                        >
                            <b-card
                                header-tag="header"
                                id="demonstracao"
                            >
                                <template #header>
                                    <b-row class="header-card">
                                        <b-col cols="8">
                                            <b> Demonstração</b>
                                        </b-col>
                                        
                                        <b-col class="text-right" cols="4">
                                            <span id="btn-scroll-trees" @click="scrollToElement('treesCol')" style="padding-left:1vw">
                                                <v-icon 
                                                    name="md-keyboarddoublearrowup-twotone"
                                                    scale="1.5"
                                                    animation="float"
                                                    speed="slow"
                                                    :hover="true"
                                                />
                                            </span>
                                            <b-tooltip target="btn-scroll-trees" triggers="hover">
                                                Clique para visualizar as árvores de edição.
                                            </b-tooltip>
                                        </b-col>

                                    </b-row>
                                </template> 
                                <b-container
                                    class="cardContainerDemonstracao"
                                    fluid
                                >
                                    <MotorS 
                                        :cp="{}" 
                                        :childrens="rootChildrens"
                                        idTela="demo"
                                        
                                    />
                                </b-container>
                            </b-card>
                        </b-col> -->
                    </b-row>
                </b-tab>
                <b-tab title="Funções">
                    <b-row>
                        <b-col class="containerViewHeight" md="4" sm="12">
                            <b-card class="containerViewHeight" id="componentes" @dblclick="dbClickCounter++">
                                <template #header>
                                    <b-row class="header-card">
                                        <b-col md="10">
                                            <b>
                                                Scripts Frontend
                                            </b>
                                        </b-col>
                                        <span @click="clearFrontendScriptsFields()" id="btn-novo-script-frontend">
                                            <v-icon name="md-playlistadd-round" scale="1.5"
                                                :animation="rootChildrens.length == 0 ? 'ring' : 'float'" speed="slow"
                                                :hover="true" />
                                        </span>
                                        <b-tooltip target="btn-novo-script-frontend" triggers="hover">
                                            Clique para cadastrar um novo Script
                                        </b-tooltip>
                                    </b-row>
                                </template>
                                <TreeView v-if="subdominio" :key="subdominio.toString()" ref="treeViewScriptsFrontend"
                                    atributeLabel="label" atributeChildren="children" atributeId="id" :axios="{
                            'url': apiFront,
                            'method': 'GET',
                            'params': {
                                'subdominio': this.subdominio
                            }
                        }" v-on:clickSimpleTree="(node) => { simpleTreeNodeSelected(node) }"
                                    v-on:doubleClickSimpleTree="(node) => { openModalFunctions(node) }"
                                    v-on:changedSimpleTreeValues="((node) => { clearFrontendScriptsFields() })" />
                            </b-card>
                        </b-col>
                        <b-col class="containerViewHeight pl-2" md="8" sm="12">
                            <b-card class="containerViewHeight" id="componentes" @dblclick="dbClickCounter++">
                                <template #header>
                                    <b-row class="header-card">
                                        <b-col md="12">
                                            <span @click="saveFrontendScripts()" id="btn-salvar-scripts-frontend"
                                                class="pl-3">
                                                <v-icon name="md-save-outlined" scale="1.5" animation="float"
                                                    speed="slow" :hover="true" />
                                            </span>

                                            <b-tooltip target="btn-salvar-scripts-frontend" triggers="hover">
                                                {{ subdominio != "" ?
                            'Clique para salvar os Scripts.' :
                            'Selecione um subdomínio'
                                                }}
                                            </b-tooltip>

                                            <span @click="frontendScriptsUndoChanges" id="btn-desfazer-scripts-frontend"
                                                class="pl-3">
                                                <v-icon name="md-undo-round" scale="1.5" animation="float" speed="slow"
                                                    :hover="true" />
                                            </span>

                                            <b-tooltip target="btn-desfazer-scripts-frontend" triggers="hover">
                                                {{ subdominio != "" ?
                            'Clique para desfazer as mudanças.' :
                            'Selecione um subdomínio'
                                                }}
                                            </b-tooltip>
                                        </b-col>
                                    </b-row>
                                </template>
                                <b-row class="p-1 w-100">
                                    <b-col v-if="selectedNodeTreeScripts.id">
                                        Script selecionado: <b>{{ selectedNodeTreeScripts.id }}</b>
                                    </b-col>
                                    <b-col v-if="!selectedNodeTreeScripts.id">
                                        Preencha os campos ID e Label para criar um novo Script:
                                    </b-col>
                                    <b-col class="m-1 mt-3" md="12">
                                        <b-row>
                                            <b-col md="3">
                                                <label class="form-label form-label-clic">
                                                    ID script
                                                    <ClicHintInterno :cp="{
                            props: {
                                hintTitle: `ID`,
                                hintText: `
                                                                O valor informado será a refêrencia para acessar as funções descritas neste script.
                                                                <br>
                                                                <br>
                                                                <p>Exemplo: <b> this.$idScript.nomeFuncao() </b></p>
                                                                `
                            }
                        }" />
                                                </label>
                                            </b-col>

                                            <b-col md="9">
                                                <b-form-input placeholder="" class="form-control"
                                                    v-model="idFrontendScript" ref="idFrontendScript">
                                                </b-form-input>
                                            </b-col>
                                        </b-row>

                                    </b-col>
                                    <b-col class="m-1" md="12">
                                        <b-row>
                                            <b-col md="3">
                                                <label class="form-label form-label-clic">
                                                    Label script
                                                    <ClicHintInterno :cp="{
                            props: {
                                hintTitle: `Label`,
                                hintText: `Informe o <b>título</b> do Script.`
                            }
                        }" />
                                                </label>
                                            </b-col>


                                            <b-col md="9">
                                                <b-form-input placeholder="" class="form-control"
                                                    v-model="labelFrontendScript">
                                                </b-form-input>
                                            </b-col>
                                        </b-row>
                                    </b-col>

                                    <b-col class="m-2" md="12">
                                        <b-input-group>
                                            <b-button v-b-modal="idFrontendScript" variant="outline-primary"
                                                :disabled="idFrontendScript == '' ? true : false">
                                                {{ this.functionsFrontendScript != ""
                                                ? "Editar Script" : "Novo Script" }}
                                            </b-button>

                                        </b-input-group>
                                        <CodeEditor :code="functionsFrontendScript" :chave="idFrontendScript"
                                            v-on:changeValueCodeEditor="(newValue) => { functionsFrontendScript = newValue}" />
                                    </b-col>
                                </b-row>

                            </b-card>
                        </b-col>
                    </b-row>

                </b-tab>
            </b-tabs>
        </b-col>
    </div>
</template>

<script>
import "@jledentu/vue-finder/dist/vue-finder.css";
import draggable from 'vuedraggable';
import nestedDraggable from "../clicDraggable/Nested.vue";
import { Finder } from "@jledentu/vue-finder";
import vueJsonEditor from 'vue-json-editor';
import Propriedades from './Propriedades.vue';
import getAllComponentsProperties from '../../core/componentsProperties'
import TreeBrowser from "./TreeBrowser.vue";
import Multiselect from './Multiselect.vue'
import TreeView from './TreeView.vue';
import CodeEditor from './CodeEditor.vue';

export default {
    name: 'CriadorTela',
    components: {
        draggable,
        nestedDraggable,
        Finder,
        vueJsonEditor,
        Propriedades,
        TreeBrowser,
        Multiselect,
        TreeView,
        CodeEditor
    },

    data() {
        return {
            apiFront: `${process.env.VUE_APP_BACKEND_URL}/api/frontend`,
            treeComponentKey: 0,
            treePropertiesKey: 0,
            containerDemoKey: 0,
            containerPropriedadesKey: 0,
            subdominios: [],
            subdominio: "",
            treeDataComponents: this.getComponents(),
            treeDataProperties: [],
            rootChildrens: [],
            propriedadesComponentes: getAllComponentsProperties(),
            dbClickCounter: 0,
            modalPropriedadesShow: false,
            tituloModalPropriedades: '',
            indexSelectedItem: null,
            isLoading: false,
            documentation: {},
            parentIdPropriedades: "",
            idFrontendScript: "",
            labelFrontendScript: "",
            functionsFrontendScript: "",
            selectedNodeTreeScripts: [],

            subdomainFromNavBar: false,

            getFromNavBar: false,

            public: {
                getSubdomainFromNavBar: this.getSubdomainFromNavBar
            }
        }
    },

    methods: {

        verificarOrigemSubdominio() {
            const componente = this.$store.getters[`${this.idTela}/getPropriedades`];
            this.getFromNavBar = this.lodash.get(componente, 'editorDeTelas.props.getSubdomainFromNavBar');
            console.log(`CriadorDeTelas - utilizando o subdominio do Multiselect ${this.getFromNavBar ? "externo" : "interno"}`)

        },
        getSubdomainFromNavBar() {
            const compNavBar = this.$store.getters[`mainFramework/getPropriedades`]
            const subdominio = compNavBar.multiSelectSubdominioTenant.getPropValue('value').nome
            return (subdominio)
        },
        frontendScriptsUndoChanges() {
            this.clearFrontendScriptsFields();
            this.$refs.treeViewScriptsFrontend.atualizarConsulta();
        },

        clearFrontendScriptsFields() {
            this.idFrontendScript = "";
            this.labelFrontendScript = "";
            this.functionsFrontendScript = "";

            this.selectedNodeTreeScripts = [];

            this.$refs.idFrontendScript.focus()
        },

        async saveFrontendScripts() {
            if (this.idFrontendScript == "" && this.selectedNodeTreeScripts.length != 0) {
                this.$notificacao({ text: `Você precisa informar o ID do script pois ele será usado como referência.`, type: 'warning' });
                return;
            };
            if (this.labelFrontendScript == "" && this.selectedNodeTreeScripts.length != 0) {
                this.$notificacao({ text: `Informe o label do Script`, type: 'warning' });
                return;
            };

            let dados = [];
            let dataTreeScripts = this.lodash.has(this, "$refs.treeViewScriptsFrontend.model.children") &&
                this.$refs.treeViewScriptsFrontend.model.children != null ? this.$refs.treeViewScriptsFrontend.model.children : []

            const index = dataTreeScripts?.findIndex(item => item.id === this.selectedNodeTreeScripts.id);

            if (dataTreeScripts[index]) {
                dataTreeScripts[index] = {
                    label: this.labelFrontendScript,
                    id: this.idFrontendScript,
                    script: this.functionsFrontendScript,
                };
            } else if (this.selectedNodeTreeScripts && !this.selectedNodeTreeScripts.id && this.idFrontendScript != "" && this.labelFrontendScript != "") {
                dataTreeScripts.push({
                    label: this.labelFrontendScript,
                    id: this.idFrontendScript,
                    script: this.functionsFrontendScript,

                })
            };

            dataTreeScripts.forEach((item) => {
                dados.push({
                    label: item.label,
                    id: item.id,
                    script: item.script,
                });
            });

            let scriptsToSend = {
                subdominio: this.subdominio,
                scripts: [...dados],
            };

            await this.gravarFrontend(scriptsToSend);
            this.$refs.treeViewScriptsFrontend.atualizarConsulta();
        },

        simpleTreeNodeSelected(node) {
            this.idFrontendScript = node.id;
            this.labelFrontendScript = node.label;
            this.functionsFrontendScript = node.script;

            this.selectedNodeTreeScripts = node;
        },

        openModalFunctions(node) {
            this.simpleTreeNodeSelected(node);
            this.$bvModal.show(this.idFrontendScript);
        },

        scrollToElement(name) {
            const el = this.$refs[name];
            if (el) {
                el.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" })
            }

        },

        selectSubdomain(subdomain) {
            this.clearFrontendScriptsFields();

            this.subdominio = subdomain;
            this.atualizarConsulta();
        },

        getSubdominios() {
            this.$axios(`${process.env.VUE_APP_BACKEND_URL}/api/subdominio`).then((response) => {
                for (let { nome } of response.data) {
                    this.subdominios.push(nome)
                }
            });
        },

        showModal() {
            this.modalPropriedadesShow = true
        },

        hideModal() {
            this.modalPropriedadesShow = false
            this.rootChildrens = [];
            this.atualizaDemonstracao();
            this.containerDemoKey++;
            this.containerPropriedadesKey++;
        },

        atualizaDemonstracao() {
            this.rootChildrens = [];
            let { estrutura } = this.convertToFrontendFormat(this.treeDataProperties);
            this.rootChildrens = estrutura[this.indexSelectedItem].childrens;
            this.containerDemoKey++;
        },

        forceUpdateTrees() {
            this.treeComponentKey += 1;
            this.treePropertiesKey += 1;
        },

        async gravarFrontend(frontend) {
            try {
                this.isLoading = true;
                const response = await this.$axios.put(`${process.env.VUE_APP_BACKEND_URL}/api/frontend`, {
                    ...frontend
                });

                if (!response.data.codigo) {
                    this.$notificacao({
                        text: `Houve um erro ao gravar a estrutura. ${response.data}`,
                        type: 'danger'
                    });
                } else {
                    this.$notificacao({
                        text: "A nova estrutura foi gravada com sucesso.",
                        type: 'success'
                    });
                }
            } catch (e) {
                this.$notificacao({
                    text: "Ocorreu um erro ao gravar a estrutura.",
                    type: 'danger'
                });
                console.error(`Ocorreu um erro ao gravar a estrutura. ${e}`);
            } finally {
                this.isLoading = false;
            }
        },

        convertToFrontendFormat(tree) {
            let nodesTree = structuredClone(tree);
            let retorno = {};
            let estrutura = [];
            try {
                for (let i = 0; i < nodesTree.length; i++) {
                    let newChildrens = [];
                    if (nodesTree[i].label != "") {
                        for (let j = 0; j < nodesTree[i].childrens.length; j++) {

                            if (nodesTree[i].childrens[j].data && nodesTree[i].childrens[j].data.component != "") {
                                newChildrens.push(
                                    {
                                        id: nodesTree[i].childrens[j].data.id,
                                        component: nodesTree[i].childrens[j].data.component,
                                        props: this.getObjectWithContent(nodesTree[i].childrens[j].data.props),
                                        events: this.getObjectWithContent(nodesTree[i].childrens[j].data.events),
                                        childrens: this.nodeHasChildren(nodesTree[i].childrens[j].childrens)
                                    }
                                )
                            }
                        }
                        nodesTree[i].data.childrens = newChildrens
                        estrutura.push(nodesTree[i].data)
                    }
                }
                retorno = {
                    subdominio: this.subdominio,
                    estrutura: estrutura && estrutura.length == 0 ? undefined : [
                        ...estrutura
                    ]
                }

                return retorno;

            } catch (e) {
                console.error("Criador de telas | convertToFrontendFormat - Detalhes do erro:", e);
            }
        },

        nodeHasChildren(node) {
            function getChildrens(nodeA) {
                if (nodeA.childrens) {
                    nodeA.childrens.forEach((child) => {
                        child = {
                            id: child.data.id,
                            component: child.data.component,
                            props: getObjectWithContent(child.data.props),
                            events: getObjectWithContent(child.data.events),
                            childrens: child.childrens ? getChildrens(child.childrens) : []
                        }
                    })
                } else {
                    return nodeA.map((item) => {
                        return {
                            id: item.data.id,
                            component: item.data.component,
                            props: getObjectWithContent(item.data.props),
                            events: getObjectWithContent(item.data.events),
                            childrens: item.childrens ? getChildrens(item.childrens) : []
                        }
                    }).filter((el) => {
                        return el.component != ""
                    })
                }
            };

            function getObjectWithContent(obj) {
                if (obj !== null && obj !== undefined) {
                    let retorno = parseStringToJson(Object.fromEntries(Object.entries(obj).filter(([_, val]) => val !== "" && val !== "null" && val !== null)));
                    return retorno
                }
            }

            function parseStringToJson(item) {
                let keysProperties = Object.keys(item);

                for (let key of keysProperties) {
                    try {
                        item[key] = JSON.parse(item[key])
                    } catch (_) {
                        item[key] = item[key]
                    }
                }
                return item
            }

            return node && getChildrens(node)
        },

        getObjectWithContent(obj) {
            if (obj !== null && obj !== undefined) {
                let retorno = this.parseStringToJson(Object.fromEntries(Object.entries(obj).filter(([_, val]) => val !== "" && val !== "null" && val !== null)));
                return retorno
            }
        },

        parseStringToJson(item) {
            let keysProperties = Object.keys(item);

            for (let key of keysProperties) {
                try {
                    item[key] = JSON.parse(item[key])
                } catch (_) {
                    item[key] = item[key]
                }
            }
            return item
        },

        salvarEstruturaGeral() {
            if (this.subdominio != "") {
                let frontendFormatado = this.convertToFrontendFormat(this.treeDataProperties);

                this.gravarFrontend(frontendFormatado);
            } else {
                this.$notificacao({ text: `Selecione um subdominio para salvar a estrutura.`, type: 'warning' });
            }

        },

        onNodeSelectedComponents(node, index) {
            this.rootChildrens = [];

            if (this.modalPropriedadesShow) {
                this.hideModal();
            };

            let timer = setTimeout(() => {
                if (this.dbClickCounter > 0) {
                    this.showModal();
                    this.dbClickCounter = 0 //double click counter reset
                    let estruturaA = this.propriedadesComponentes[node.data.component]
                    let estrutura = structuredClone(estruturaA);

                    if (!node.data.props) {
                        node.data.props = {}
                    }
                    if (!node.data.events) {
                        node.data.events = {}
                    }

                    this.documentation = estrutura.config.documentation

                    for (const [key, value] of Object.entries(estrutura.props)) {
                        if (this.documentation) {
                            estrutura.props[key].documentation = this.documentation[key];
                        };
                    }

                    for (const [key, value] of Object.entries(estrutura.events)) {
                        if (this.documentation) {
                            estrutura.events[key].documentation = this.documentation[key];
                        };
                    }

                    this.$refs.compPropriedades.atualizarConsulta(estrutura, node.data);
                    this.parentIdPropriedades = node.data.id

                    this.tituloModalPropriedades = node.data?.id || '';

                } else {
                    this.dbClickCounter = 0;
                }

                if (node.data.component === "LayoutMenuTopoConteudo" ||
                    node.data.component === "LayoutTopoConteudo" ||
                    node.data.component === "LayoutVazio") {
                    //Atualiza index do item selecionado
                    this.indexSelectedItem = index;
                }

                this.atualizaDemonstracao()

            }, 300)

            this.dbClickCounter = 0;
            timer = null;
        },

        montarPropriedadesComponente(modelo, props) {
            let atributosPropriedadesDoComponente = Object.keys(props);
            if (modelo) {
                for (let atributoPropComp of atributosPropriedadesDoComponente) {
                    if (modelo[atributoPropComp]) {
                        if (!modelo[atributoPropComp].type || modelo[atributoPropComp].type == 'object') {
                            modelo[atributoPropComp] = this.montarPropriedadesComponente(modelo[atributoPropComp], props[atributoPropComp]);
                        } else {
                            modelo[atributoPropComp].value = props[atributoPropComp];
                            modelo[atributoPropComp].key = atributoPropComp;
                        }
                    }
                }
            }

            return modelo;
        },

        atualizarConsulta() {
            this.$axios(`${process.env.VUE_APP_BACKEND_URL}/api/frontendtree?subdominio=${this.subdominio}`).then((response) => {
                if (response.data && response.data.length > 0) {
                    this.treeDataProperties = response.data;
                } else {
                    this.treeDataProperties =
                        [//Quando o retorno é vazio, cria um item falso.
                            {
                                "label": "",
                                "data": {
                                    "id": "",
                                    "component": "",
                                    "props": {},
                                    "childrens": []
                                }
                            }
                        ]
                }

                this.forceUpdateTrees();
            });
        },

        getComponents() {
            let componentes = getAllComponentsProperties(),
                retorno = [];

            Object.entries(componentes).forEach(item => {
                retorno.push(
                    {
                        "label": item[0],
                        "config": {
                            "icon": item[1].config && item[1].config.icon,
                            "isContainer": item[1].config && item[1].config.isContainer,
                            "isRoute": item[1].config && item[1].config.isRoute
                        },
                        "data": {
                            "component": item[0],
                            "id": "",
                            "props": {},
                            "events": {}
                        },
                        "childrens": []
                    },
                )
            })
            return retorno;
        }
    },

    props: {
        cp: {
            default: "",
        },
        idTela: {
            type: String,
            default: "",
        }
    },

    mounted() {
        this.atualizarConsulta();
        this.getSubdominios();
        this.verificarOrigemSubdominio()

        this.$eventBus.$on('subdominioAlteradoNavbar', (subdominio) => {
            if (this.getFromNavBar) {
                this.selectSubdomain(subdominio)
            }
        });


    },

    created() {
        this.$store.commit(`${this.idTela}/setPropriedades`, { [`${this.cp.id}`]: { ...this.cp, atualizarConsulta: this.atualizarConsulta } });
    },
    watch: {
        treeDataProperties: {
            handler: function () {
                if (this.rootChildrens && this.rootChildrens.length > 0) {
                    this.atualizaDemonstracao()
                }
            },
            deep: true
        }
    },
}

</script>

<style>
.bg-custom {
    background-color: var(--secondary);
    border-radius: 5px;
    padding: 0;
}

#guias-criador-de-telas .nav-tabs .nav-link:hover {
    color: white;
    transform: scale(1.03);
}

#guias-criador-de-telas .nav-tabs .nav-link {
    color: white !important
}

#guias-criador-de-telas .nav-tabs .nav-link.active {
    color: white !important
}

#guias-criador-de-telas .nav-tabs .nav-link.active:after {
    background: white !important
}
</style>

<style scoped>
.nav-tabs {
    border-bottom: 0;
}

.card-body {
    padding: 5px
}

.aviso-subdominio {
    font-size: 12px;
    color: grey;
    font-weight: 200;
}

.dragArea {
    min-height: 50px;
    outline: 1px dashed;
}

.containerViewHeight {
    height: calc(100vh - 136px) !important;
    overflow-y: auto;
    padding: 0;
}

#demonstracao {
    /* height: 100%; */
    height: 100%
}

.containerDemonstracao {
    background-color: white;
    height: 100%;
    margin-top: 15px;
    border-radius: 10px;
    padding: 0;
}

.cardContainerDemonstracao {
    height: calc(100vh - 150px) !important;
    overflow-y: scroll;
    background-color: white;
}

body {
    background-color: #333;
    color: white;
}

#criador {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    margin-top: 60px;
}

.header-card {
    padding: 0;
    height: 25px;
}

.card-deck .card {
    margin-top: 7vh;
    border-width: 1px 1px 1px 1px;
    border-color: rgb(210, 210, 210);
    width: 50vw;
    height: 92vh;
    border-radius: 5px;
    padding: 0
}

.side-menu {
    height: 72vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.container-modal-actions {
    justify-content: space-evenly;
    display: flex;
}
</style>
