<template>
  <ClicColInterno alignSelf="end" :cp="cp" :key="componentKey">

    <b-row>
      <b-col :md="mdColumnList" v-if="showList">
        <b-card>
          <b-card-header class="text-center">
            <h5>Próximos Eventos</h5>
          </b-card-header>
          <b-card-body>
            <b-input-group class="mb-1">
              <b-form-input v-model="searchQuery" placeholder="Pesquisar eventos"></b-form-input>
              <b-input-group-append>
                <b-button id="ocultar-concluidos-btn" :variant="disableCompletedEvents ? 'primary' : 'outline-primary'"
                  @click="handleDisableCompleted()">
                  {{ messageDisabledCompletedEvents }}
                </b-button>
                <b-tooltip target="ocultar-concluidos-btn"
                  title="Clique para ocultar ou mostrar eventos concluídos"></b-tooltip>
                <b-button id="novo-evento-btn" variant="primary" @click="defineInputsModalCreate(new Date())">
                  <ClicIconInterno iconName="bi-plus" iconTitle="Mais" />
                  Novo
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-for="(events, day) in paginatedEvents" :key="day">
              <b-card-header class="d-flex justify-content-between align-items-center bg-light py-2 px-3">
                <div>
                  <p class="mb-0">{{ formatDateForList(day) }}</p>
                </div>
                <div>
                  <p class="mb-0">{{ formatDayOfWeek(day) }}</p>
                </div>
              </b-card-header>
              <b-list-group>
                <b-list-group-item v-for="event in events" :key="event._id" @click="defineInputsModalEdit(event)"
                  class="d-flex justify-content-between align-items-center">
                  <span class="text-left">
                    <ClicIconInterno :iconName="getSituationIcon(event.situacao.value)" :title="event.situacao.text" />
                  </span>
                  <span class="text-left"><strong>{{ event.title }}</strong></span>
                  <span class="text-left">Inicia {{ formatTimeForList(event.start) }}</span>
                  <span class="text-left">Termina {{ formatTimeForList(event.end, event.start) }}</span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div class="d-flex justify-content-center mt-3">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="registerPerPageList"
                aria-controls="my-table"></b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col v-if="!hideCalendar">
        <vue-cal ref="vuecal" :activeView="activeView" :allDayBarHeight="allDayBarHeight" :cellClickHold="cellClickHold"
          :cellContextmenu="cellContextmenu" :clickToNavigate="clickToNavigate" :dblclickToNavigate="dblclickToNavigate"
          :disableDatePrototypes="disableDatePrototypes" :disableDays="disableDays" :disableViews="disableViews"
          :dragToCreateEvent="dragToCreateEvent" :dragToCreateThreshold="dragToCreateThreshold" :editableEvents="false"
          :events="events" :eventsCountOnYearView="eventsCountOnYearView" :eventsOnMonthView="eventsOnMonthView"
          :hideBody="hideBody" :hideTitleBar="hideTitleBar" :hideViewSelector="hideViewSelector"
          :hideWeekdays="hideWeekdays" :hideWeekends="hideWeekends" :locale="locale" :maxDate="maxDate"
          :minCellWidth="minCellWidth" :minDate="minDate" :minEventWidth="minEventWidth" :minSplitWidth="minSplitWidth"
          :overlapsPerTimeStep="overlapsPerTimeStep" :resizeX="resizeX" :selectedDate="selectedDate"
          :showAllDayEvents="showAllDayEvents" :showTimeInCells="showTimeInCells" :showWeekNumbers="showWeekNumbers"
          :small="small" :snapToTime="snapToTime" :specialHours="specialHours" :splitDays="splitDays"
          :startWeekOnSunday="startWeekOnSunday" :stickySplitLabels="stickySplitLabels" :time="time"
          :timeCellHeight="timeCellHeight" :timeFormat="timeFormat" :timeFrom="timeFrom" :timeStep="timeStep"
          :timeTo="timeTo" :todayButton="todayButton" :transitions="transitions" :twelveHour="twelveHour"
          :xsmall="xsmall" :watchRealTime="watchRealTime" :class="this.class" :style="this.style"
          @cell-click="onEventClick" @cell-dblclick="onEventDblclick" @event-drag-create=""
          @event-delete="onEventDelete" @event-focus="onEventFocus" @event-create="" @view-change="onViewChange"
          @cell-contextmenu="" @cell-focus="" @event-mouse-enter="" @event-mouse-leave="" @event-title-change=""
          @event-content-change="" @event-duration-change="" @event-drop="">

          <template v-if="showEventIcon" #event="{ event }">
            <ClicIconInterno :iconName="getSituationIcon(event.situacao.value)" />

          </template>

        </vue-cal>
      </b-col>
    </b-row>

    <b-modal id="create-event-modal" size="lg" :title="isEditingEvent == true ? 'Editar Evento' : 'Criar Evento'">
      <b-row>
        <b-col>
          <template>
            <div>
              <b-row>
                <b-col md="3">
                  <label class="mb-0" for="event-title">Título</label>
                  <b-form-input id='event-title' v-model="selectedEvent.title" placeholder="Título"
                    class="mb-2"></b-form-input>
                </b-col>
                <b-col md="4" >
                  <label class="mb-0" for="group-class">Estilo</label>
                  <b-input-group id='group-class' style="flex-wrap: nowrap !important;">
                    <b-input-group-prepend is-text>
                      <div class="color-block" :class="selectedEvent.class"></div>
                    </b-input-group-prepend>
                    <b-form-select id='event-class' v-model="selectedEvent.class" :options="colorOptions">
                    </b-form-select>
                  </b-input-group>
                </b-col>
                <b-col md="4" class="mx-2">
                  <div v-show="!isEditingEvent">
                    <label class="mb-0 d-block" for="event-recorrencia">Recorrência</label>
                    <b-form-checkbox id="event-recorrencia" v-model="isRecurrent" class="mt-1">
                      Ativa
                    </b-form-checkbox>
                  </div>
                  <div v-show="isEditingEvent" >
                    <label class="mb-0" for="event-situacao">Situação</label>
                    <multiselect disabled id='event-situacao' v-model="selectedEvent.situacao"
                      :placeholder="'Selecione'" label="text" :options="situations" :multiple="false">
                    </multiselect>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div>
              <b-row>
                <b-col md="7">
                  <label class="mb-0" for="event-startDate">Data Inicio</label>
                  <b-form-datepicker id="event-startDate" v-model="selectedEvent.startDate" :locale="this.getLocale()"
                    class="mb-2">
                  </b-form-datepicker>
                </b-col>
                <b-col>
                  <label class="mb-0" for="event-startTime">HoraInício</label>
                  <b-form-timepicker id="event-startTime" v-model="selectedEvent.startTime" locale="de"
                    minutes-step="5">
                  </b-form-timepicker>
                </b-col>
              </b-row>
            </div>
            <div>
              <b-row>
                <b-col md="7">
                  <label class="mb-0" for="event-endDate">Data fim</label>
                  <b-form-datepicker id="event-endDate" v-model="selectedEvent.endDate" :locale="this.getLocale()"
                    class="mb-2"></b-form-datepicker>
                </b-col>
                <b-col>
                  <label class="mb-0" for="event-endTime">Hora Fim</label>
                  <b-form-timepicker id="event-endTime" v-model="selectedEvent.endTime" locale="de"
                    minutes-step="5"></b-form-timepicker>
                </b-col>
              </b-row>
            </div>
            <div v-if="isRecurrent">
              <b-row class="mt-2">
                <b-col md="2">
                  <label class="mb-0">Repetir</label>
                  <b-form-input id='event-recurrent-period' v-model="recurrentConfig.period" class="mb-2"
                    type="number"></b-form-input>
                </b-col>
                <b-col md="3">
                  <label class="mb-0" for="event-recurrent-period"> a cada:</label>
                  <multiselect id='event-recurrent-period' v-model="recurrentConfig.interval" :placeholder="'Selecione'"
                    label="text" :options="recurrentIntervals" :multiple="false">
                  </multiselect>
                </b-col>
                <b-col>
                  <label class="mb-0" for="event-recurrent-endDate">Fim da Repetição</label>
                  <b-form-datepicker id="event-recurrent-endDate" v-model="recurrentConfig.endDate"
                    :locale="this.getLocale()" class="mb-2"></b-form-datepicker>
                </b-col>
              </b-row>
            </div>
            <b-row class="mt-2">
              <b-col>
                <label class="mb-0" for="event-observation">Observação</label>
                <b-form-textarea id="event-observation" v-model="selectedEvent.observation"
                  placeholder="Adicione suas observações aqui" rows="2"></b-form-textarea>
              </b-col>
            </b-row>
          </template>

          <template v-if="getSlotModal()">
            <MotorS :cp="cp" :childrens="getSlotModal()" :idTela="idTela" :state="state" />
          </template>

        </b-col>
        <b-col md="5" v-if="showModalCalendar">
          <vue-cal :events="events" :selected-date="selectedEvent.startDate" view="day" style="height: 400px"
            :hide-view-selector="true" :disable-views="['years', 'year', 'month', 'week']" :locale="locale">
          </vue-cal>
        </b-col>


      </b-row>

      <template v-slot:modal-footer>

        <template v-if="getSlotAcao() && !situationsOfConcludedEvents.includes(getSelectedEventSituation())">
          <MotorS :cp="cp" :childrens="getSlotAcao()" :idTela="idTela" :state="state" />
        </template>

        <b-dropdown v-if="isEditingEvent && !situationsOfConcludedEvents.includes(getSelectedEventSituation())"
          variant="outline-danger">
          <template v-slot:button-content>
            Excluir Evento
            <ClicIconInterno iconName="md-keyboardarrowdown" iconTitle="Opções" />
          </template>
          <b-dropdown-item @click="$bvModal.show('delete-event-modal')">
            Excluir este evento
          </b-dropdown-item>
          <b-dropdown-item v-if="selectedEvent.recurrentId" @click="$bvModal.show('delete-recurrents-modal')">
            Excluir este evento e suas recorrências
          </b-dropdown-item>
          <b-dropdown-item v-if="selectedEvent.recurrentId" @click="$bvModal.show('delete-following-modal')">
            Excluir este evento e os eventos seguintes
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown v-if="isEditingEvent && !situationsOfConcludedEvents.includes(getSelectedEventSituation())"
          variant="outline-secondary">
          <template v-slot:button-content>
            Editar Evento
            <ClicIconInterno iconName="md-keyboardarrowdown" iconTitle="Opções" />
          </template>
          <b-dropdown-item @click="defineObjectEditEvent('single')">
            Editar este evento
          </b-dropdown-item>
          <b-dropdown-item v-if="selectedEvent.recurrentId" @click="$bvModal.show('edit-serie-modal')">
            Editar este evento e suas recorrências
          </b-dropdown-item>
          <b-dropdown-item v-if="selectedEvent.recurrentId" @click="$bvModal.show('edit-following-modal')">
            Editar este evento e os eventos seguintes
          </b-dropdown-item>
        </b-dropdown>

        <b-button v-if="selectedEvent.recurrentId && !situationsOfConcludedEvents.includes(getSelectedEventSituation())"
          variant="outline-primary" @click="defineInputsModalRecurrent()">
          Editar Recorrência
        </b-button>

        <b-button v-if="!isEditingEvent" variant="outline-secondary" @click="defineObjectCreateEvent()">
          Criar Evento
        </b-button>

      </template>
    </b-modal>


    <b-modal id="delete-event-modal" size="s" title="Excluir Evento">
      <template>
        <p>Deseja realmente excluir o evento?</p>
      </template>
      <template v-slot:modal-footer>
        <b-button variant="outline-danger" style="border: none" @click="defineObjectDeleteEvent('single')">Excluir</b-button>
        <b-button variant="secondary" @click="$bvModal.hide('delete-event-modal')">Cancelar</b-button>
      </template>
    </b-modal>

    <b-modal id="delete-recurrents-modal" size="s" title="Excluir todas as recorrências">
      <template>
        <p>Deseja realmente excluir o evento e suas recorrências?</p>
      </template>
      <template v-slot:modal-footer>
        <b-button variant="outline-danger" style="border: none" @click="defineObjectDeleteEvent('serie')">
          Excluir Recorrências
        </b-button>
        <b-button variant="secondary" @click="$bvModal.hide('delete-event-modal')">
          Cancelar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="delete-following-modal" size="s" title="Excluir todas as recorrências">
      <template>
        <p>Deseja realmente excluir o evento e suas recorrências seguintes?</p>
      </template>
      <template v-slot:modal-footer>
        <b-button variant="outline-danger" style="border: none" @click="defineObjectDeleteEvent('following')">
          Excluir Recorrências
        </b-button>
        <b-button variant="secondary" @click="$bvModal.hide('delete-following-modal')">
          Cancelar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="edit-serie-modal" size="s" title="Editar todas as recorrências">
      <template>
        <p>Deseja realmente editar o evento e suas recorrências? </p>
        <p>Todas as informações com exceção da data serão sobrescrevidas.</p>
      </template>
      <template v-slot:modal-footer>
        <b-button variant="outline-primary" style="border: none" @click="defineObjectEditEvent('serie')">
          Editar Recorrências
        </b-button>
        <b-button variant="secondary" @click="$bvModal.hide('edit-serie-modal')">
          Cancelar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="edit-following-modal" size="s" title="Editar evento e os eventos seguintes">
      <template>
        <p>Deseja realmente editar o evento e suas recorrências seguintes? </p>
        <p>Todas as informações com exceção da data serão sobrescrevidas.</p>
      </template>
      <template v-slot:modal-footer>
        <b-button  variant="outline-primary" style="border: none" @click="defineObjectEditEvent('following')">
          Editar Recorrências</b-button>
        <b-button variant="secondary" @click="$bvModal.hide('edit-serie-modal')">
          Cancelar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="edit-recurrent-modal" size="s" title="Editar a recorrência">
      <template>
        <p>Ao alterar a recorrência novos eventos serão gerados. Campos personalizados sofrerão alteração.
        </p>
        <b-row class="mt-2">
          <b-col>
            <label class="mb-2" for="recurrent-endDate">Novo inicio da repetição:</label>
            <b-form-datepicker id="recurrent-endDate" v-model="recurrentConfig.newStartDate" :locale="this.getLocale()"
              class="mb-2">
            </b-form-datepicker>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="2">
            <label class="mb-0">Repetir</label>
            <b-form-input id='event-recurrent-period' v-model="recurrentConfig.period" class="mb-2"
              type="number"></b-form-input>
          </b-col>
          <b-col md="3">
            <label class="mb-0" for="event-recurrent-period"> a cada:</label>
            <multiselect id='event-recurrent-period' v-model="recurrentConfig.interval" :placeholder="'Selecione'"
              label="text" :options="recurrentIntervals" :multiple="false">
            </multiselect>
          </b-col>
          <b-col>
            <label class="mb-0" for="event-recurrent-endDate">Fim da Repetição</label>
            <b-form-datepicker id="event-recurrent-endDate" v-model="recurrentConfig.newEndDate"
              :locale="this.getLocale()" class="mb-2"></b-form-datepicker>
          </b-col>
        </b-row>
      </template>
      <template v-slot:modal-footer>
        <b-button  variant="outline-primary" style="border: none" @click="defineObjectEditRecurrent()">
          Editar Recorrência
        </b-button>
        <b-button variant="secondary" @click="$bvModal.hide('edit-serie-modal')">
          Cancelar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="lost-sell-modal" size="s" title="Marcar como Perda">
      <template>
        <p>Selecione o motivo da perda</p>
        <b-row class="mt-1">
          <multiselect id='event-situacao-perda' v-model="selectedReasonForLoss" :placeholder="'Selecione'" label="text"
            :options="reasonsForLoss" :multiple="false">
          </multiselect>
        </b-row>
      </template>
      <template v-slot:modal-footer>
        <b-button variant="danger" @click="concluirAgenda(selectedEvent, 'PERDA', 'single')">
          Marcar como perda
        </b-button>
        <b-button variant="secondary" @click="$bvModal.hide('lost-sell-modal')">
          Cancelar
        </b-button>
      </template>
    </b-modal>

  </ClicColInterno>
</template>
<script>

import ComponentMixin from '../../core/ComponentMixin';
import VueCal from 'vue-cal';
import Multiselect from 'vue-multiselect';
import 'vue-cal/dist/vuecal.css';

export default {
  name: 'ClicCalendar',
  components: { VueCal, Multiselect },
  mixins: [ComponentMixin],
  data() {
    return {
      public: {
        setEvents: this.setEvents,
        concluirAgenda: this.concluirAgenda,
        marcarComoPerda: this.marcarComoPerda,
        marcarComoGanho: this.marcarComoGanho,
        marcarComoCancelado: this.marcarComoCancelado,
        marcarComoConcluido: this.marcarComoConcluido,
        marcarComoPendente: this.marcarComoPendente,
        disableCompletedEvents: true,
        selectedEvent: {}
      },

      operationTypes: ["following", "serie", "single"],
      events: [],
      recurrentConfig: {},
      selectedEvent: {},
      actualViewForCalendar: {},

      messageDisabledCompletedEvents: "Mostrar todos",
      disableCompletedEvents: true,
      isEditingEvent: false,
      isRecurrent: false,

      colorOptions: [
        { value: 'clic', text: 'Clic' },
        { value: 'black', text: 'Preto' },
        { value: 'green', text: 'Verde' },
        { value: 'blue', text: 'Azul' },
        { value: 'pink', text: 'Rosa' },
        { value: 'yellow', text: 'Amarelo' },
        { value: 'purple', text: 'Roxo' },
        { value: 'red', text: 'Vermelho' },
        { value: 'gray', text: 'Cinza' },
        { value: 'cyan', text: 'Ciano' },
        { value: 'brown', text: 'Marrom' },
        { value: 'indigo', text: 'Índigo' },
        { value: 'olive', text: 'Oliva' },
      ],
      recurrentIntervals: [
        { value: 'd', text: 'Dia(s)' },
        { value: 's', text: 'Semana(s)' },
        { value: 'm', text: 'Mês(s)' }
      ],

      subdominio: this.$host.subdominio,

      selectedReasonForLoss: { value: 'NAO_ATENDE', text: 'Cliente não atende' },

      searchQuery: "",
      currentPage: 1,
      totalRows: 1,
    };
  },
  created() {
    this.locale = this.getLocale();
    this.timeZone = this.getTimezone();
    this.setEvents();

  },
  mounted() {
  },
  watch: {
    selectedEvent: {
      handler(newVal) {
        this.public.selectedEvent = newVal;
      },
      deep: true
    },
    disableCompletedEvents: {
      handler(newVal) {
        this.public.disableCompletedEvents = newVal;
      },
      deep: true
    }
  },
  computed: {
    filteredEvents() {
      let events = this.events;

      if (this.searchQuery) {
        events = events.filter(event =>
          event.title.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }

      events = events.sort((a, b) => new Date(a.start) - new Date(b.start));
      return (events.filter((evnt) => new Date(evnt.start > new Date())))
    },
    paginatedEvents() {
      if (this.events) {
        this.totalRows = this.events.length
        const start = (this.currentPage - 1) * this.registerPerPageList;
        const end = this.currentPage * this.registerPerPageList;
        const paginated = this.filteredEvents.slice(start, end);

        return paginated.reduce((groups, event) => {
          const day = event.start.split(' ')[0];
          if (!groups[day]) {
            groups[day] = [];
          }
          groups[day].push(event);
          return groups;
        }, {});
      }
    }
  },
  methods: {

    getLocale() {
      const frameLanguage = this.$storage.getIdioma().toLowerCase();
      switch (frameLanguage) {
        case "pt": return "pt-br";
        default: return frameLanguage
      }
    },
    getTimezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone; 
    },
    onEventClick($event) {
      if ($event) {
        this.defineInputsModalCreate($event)
      }

      if (this.lodash.has(this.cp, 'events.onEventClick')) {
        try {
          eval(this.cp.events.onEventClick);
        } catch (e) {
          console.error(`[ClicCalendar] Falha ao executar evendo 'onEventClick'. Detalhes: ${e}`);
        }
      }
    },
    onEventDblclick($event) {
      if ($event) {
        this.defineInputsModalCreate($event)
      }

      if (this.lodash.has(this.cp, 'events.onEventDblclick')) {
        try {
          eval(this.cp.events.onEventDblclick);
        } catch (e) {
          console.error(`[ClicCalendar] Falha ao executar evendo 'onEventDblclick'. Detalhes: ${e}`);
        }
      }
    },
    onEventCreate($event) {
      if (this.lodash.has(this.cp, 'events.onEventCreate')) {
        try {
          eval(this.cp.events.onEventCreate);
        } catch (e) {
          console.error(`[ClicCalendar] Falha ao executar evendo 'onEventCreate'. Detalhes: ${e}`);
        }
      }
    },
    onEventFocus($event) {
      if ($event) {
        this.defineInputsModalEdit($event)
      }

      if (this.lodash.has(this.cp, 'events.onEventFocus')) {
        try {
          eval(this.cp.events.onEventFocus);
        } catch (e) {
          console.error(`[ClicCalendar] Falha ao executar evendo 'onEventFocus'. Detalhes: ${e}`);
        }
      }
    },
    onEventDelete($event) {
      if (this.lodash.has(this.cp, 'events.onEventDelete')) {
        try {
          eval(this.cp.events.onEventDelete);
        } catch (e) {
          console.error(`[ClicCalendar] Falha ao executar evendo 'onEventDelete'. Detalhes: ${e}`);
        }
      }
    },
    onViewChange($view) {
      this.actualViewForCalendar = $view
      this.setEvents()
      if (this.lodash.has(this.cp, 'events.onViewChange')) {
        try {
          eval(this.cp.events.onEventDelete);
        } catch (e) {
          console.error(`[ClicCalendar] Falha ao executar evendo 'onViewChange'. Detalhes: ${e}`);
        }
      }
    },



    //-------Públicos-------
    setEvents(data) {
      if (data) {
        this.events = data
      } else {
        this.getEvents().then((events) => {
          this.events = events
        })
      }
      this.hideModalsClearVariables()
    },

    hideModalsClearVariables() {
      this.$bvModal.hide('delete-recurrents-modal')
      this.$bvModal.hide('delete-following-modal')
      this.$bvModal.hide('edit-following-modal')
      this.$bvModal.hide('edit-recurrent-modal')
      this.$bvModal.hide('create-event-modal')
      this.$bvModal.hide('delete-event-modal')
      this.$bvModal.hide('edit-serie-modal')
      this.$bvModal.hide('lost-sell-modal')
      this.clearPersonalizedInputs();
      this.recurrentConfig = {}
      this.isRecurrent = false;
    },

    handleDisableCompleted() {
      try {
        if (this.disableCompletedEvents) {
          this.disableCompletedEvents = false
          this.messageDisabledCompletedEvents = "Ocultar concluídos"
        } else {
          this.disableCompletedEvents = true
          this.messageDisabledCompletedEvents = "Mostrar todos"
        }
        this.setEvents()

      } catch (error) {
        console.log(error);
      }
    },

    concluirAgenda(evento, situation, editType = 'single') {
      return new Promise(async (resolve, reject) => {
        try {

          let idsToReply = []

          if (!evento || !evento._id) {
            resolve(console.log("Informe o evento para concluir a agenda"));
          }

          if (situation && !this.situationsOfConcludedEvents.some(v => editType === v)) {
            resolve(console.log(`Situação inválido. Opções disponíveis: ${this.situationsOfConcludedEvents.toString()}`));
          }

          if (editType && !this.operationTypes.some(v => editType === v)) {
            resolve(console.log(`Tipo de operação inválido. Opções disponíveis: ${this.operationTypes.toString()}`));
          }

          if (editType !== 'single') {
            let eventos = await this.getEvents({
              id: evento._id,
              editType:
                editType == "following" ? 'following' :
                  editType == "serie" ? "serie" :
                    null
            });

            if (!Array.isArray(eventos)) { eventos = [eventos] }

            idsToReply = eventos.filter(event => event._id !== idEvento);
            idsToReply = idsToReply.map(event => ({ _id: event._id }));
          }

          const diffMinutes = this.getTimeDifference();
          if (diffMinutes <= 0) { return (this.$notificacao({ text: "Não foi possível concluit a agenda. A hora de início deve ser menor que a hora do término do evento", type: 'danger' })); }

          const formattedObject = {
            "slot": { ...this.getPersonalizedInputs() },
            "start": `${evento.startDate} ${evento.startTime}`,
            "end": `${evento.endDate} ${evento.endTime}`,
          };

          const { startDate, endDate, startTime, endTime, ...event } = this.lodash.merge(evento, formattedObject)

          const foundSituation = this.situations.find(s => s.value === situation);
          if (situation == "PERDA") { foundSituation.motivoPerda = this.selectedReasonForLoss }
          event.situacao = foundSituation

          await this.putEvent(event, idsToReply)
          this.setEvents()
          resolve()

        } catch (error) {
          reject(error);
        }
      });
    },

    marcarComoPerda(event) {
      this.selectedEvent = event
      if (this.selectedEvent) {
        this.$bvModal.show('lost-sell-modal');
      }
    },

    marcarComoGanho(event) {
      this.selectedEvent = event
      this.concluirAgenda(event, "GANHO");
    },

    marcarComoCancelado(event) {
      this.selectedEvent = event
      this.concluirAgenda(event, "CANCELADO");
    },

    marcarComoConcluido(event) {
      this.selectedEvent = event
      this.concluirAgenda(event, "CONCLUIDO");
    },

    marcarComoPendente(event) {
      this.selectedEvent = event
      this.concluirAgenda(event, "PENDENTE")
    },



    //-------Privados------------

    defineInputsModalCreate(event) {
      const date = new Date(event);
      const formattedDate = this.formatDate(date);

      let roundedMinutes = Math.round(date.getMinutes() / 5) * 5;
      if (roundedMinutes === 60) { roundedMinutes = 55 }

      const minutes = String(roundedMinutes).padStart(2, '0');
      const hourStart = String(date.getHours()).padStart(2, '0');
      const hourEnd = String(date.getHours() + 1).padStart(2, '0');

      this.selectedEvent = {
        startDate: formattedDate,
        endDate: formattedDate,
        startTime: `${hourStart}:${minutes}`,
        endTime: `${hourEnd}:${minutes}`,
        title: "Novo Evento",
        class: 'clic',
      };

      this.isEditingEvent = false;
      this.recurrentConfig.endDate = this.selectedEvent.startDate;

      this.clearPersonalizedInputs();
      this.$bvModal.show('create-event-modal');
    },

    defineObjectCreateEvent() {
      const selectedEvent = this.selectedEvent;
      const diffMinutes = this.getTimeDifference();

      if (diffMinutes <= 0) {
        return (this.$notificacao({ text: "Não foi possível criar esse evento. A hora de início deve ser menor que a hora do término do evento", type: 'danger' }))
      }

      if (this.isRecurrent && (!this.recurrentConfig.interval || !this.recurrentConfig.period)) {
        return (this.$notificacao({ text: "Preencha corretamente as configurações de recorrência", type: 'danger' }))
      }

      const event = {
        "slot": { ...this.getPersonalizedInputs() },
        "start": `${selectedEvent.startDate} ${selectedEvent.startTime}`,
        "startTime": `${selectedEvent.startTime}`,
        "end": `${selectedEvent.endDate} ${selectedEvent.endTime}`,
        "endTime": `${selectedEvent.endTime}`,
        "title": selectedEvent.title,
        "class": selectedEvent.class,
        "situacao": { value: 'PENDENTE', text: 'Pendente' }
      }

      this.postEvent(event, this.isRecurrent ? this.recurrentConfig : null).then(() => { this.setEvents() })

    },

    defineInputsModalEdit(event) {

      const formattedObject = {
        startDate: this.formatDate(new Date(event.start)),
        endDate: this.formatDate(new Date(event.end)),
        startTime: this.formatTime(new Date(event.start)),
        endTime: this.formatTime(new Date(event.end))
      };

      this.selectedEvent = this.lodash.merge(event, formattedObject);
      this.isEditingEvent = true;

      this.$nextTick(() => { this.definePersonalizedInputs(event.slot); });
      this.$bvModal.show('create-event-modal');
    },

    defineObjectEditEvent(editType) {
      return new Promise(async (resolve, reject) => {
        const selectedEvent = this.selectedEvent;
        const diffMinutes = this.getTimeDifference();
        let replyForIds = []

        if (selectedEvent._id) {

          if (editType && !this.operationTypes.some(v => editType === v)) {
            resolve(console.log(`Tipo de operação inválido. Opções disponíveis: ${this.operationTypes.toString()}`));
          }

          if (diffMinutes <= 0) {
            return (this.$notificacao({ text: "Não foi possível editar esse evento. A hora de início deve ser menor que a hora do término do evento", type: 'danger' }));
          }

          const formattedObject = {
            "slot": { ...this.getPersonalizedInputs() },
            "start": `${selectedEvent.startDate} ${selectedEvent.startTime}`,
            "end": `${selectedEvent.endDate} ${selectedEvent.endTime}`,
          };

          const { startDate, endDate, startTime, endTime, ...event } = this.lodash.merge(selectedEvent, formattedObject)

          if (editType == 'serie') {
            replyForIds = await this.getSerieEvents(selectedEvent, 'serie');
          } else if (editType == 'following') {
            replyForIds = await this.getSerieEvents(selectedEvent, 'following');
          }

          this.putEvent(event, replyForIds).then(() => { this.setEvents() })
        }
      });
    },

    defineInputsModalRecurrent() {
      return new Promise(async (resolve, reject) => {
        const params = { "id": this.selectedEvent.recurrentId }
        this.recurrentConfig = await this.getRecurrent(params);

        this.$bvModal.hide('create-event-modal');
        this.$bvModal.show('edit-recurrent-modal');

        this.recurrentConfig.newStartDate = this.recurrentConfig.startDate;
        this.recurrentConfig.newEndDate = this.recurrentConfig.endDate;

        resolve();
      });
    },

    defineObjectEditRecurrent() {
      return new Promise(async (resolve, reject) => {
        const selectedEvent = this.selectedEvent;
        const recurrent = this.recurrentConfig;

        await this.defineObjectDeleteEvent('serie');
        await this.postEvent(selectedEvent, recurrent);
        await this.postRecurrent(recurrent);
        this.setEvents();

        resolve();

      });
    },

    defineObjectDeleteEvent(deleteType) {
      return new Promise(async (resolve, reject) => {

        let ids = []
        const selectedEvent = this.selectedEvent;

        if (deleteType && !this.operationTypes.some(v => deleteType === v)) {
          resolve(console.log(`Tipo de operação inválido. Opções disponíveis: ${this.operationTypes.toString()}`));
        }

        if (deleteType == 'single') {
          await this.deleteEvent()
          resolve()

        } else if (deleteType == 'serie') {
          ids = await this.getSerieEvents(selectedEvent, 'serie');

        } else if (deleteType == 'following') {
          ids = await this.getSerieEvents(selectedEvent, 'following');
        }

        ids = ids.map(event => ({ _id: event._id }));
        await this.deleteEvent(ids);
        resolve();
      })
    },

    getSerieEvents(selectedEvent, getType) {
      return new Promise(async (resolve, reject) => {

        if (getType && !this.operationTypes.some(v => getType === v)) {
          resolve(console.log(`Tipo de operação inválido. Opções disponíveis: ${this.operationTypes.toString()}`));
        }

        const events = await this.getEvents({
          id: selectedEvent._id,
          searchType: getType == "following" ? "following" : "serie"
        })

        const diffStart = new Date(selectedEvent.start) - new Date(selectedEvent.previousDate.start);
        const diffEnd = new Date(selectedEvent.end) - new Date(selectedEvent.previousDate.end);

        let replyForIds = events.map(evnt => {
          const evntStartDateTime = new Date(evnt.previousDate.start);
          const evntEndDateTime = new Date(evnt.previousDate.end);
          const newStartDateTime = new Date(evntStartDateTime.getTime() + diffStart);
          const newEndDateTime = new Date(evntEndDateTime.getTime() + diffEnd);

          const createEvent = (id, start, end, previousDate) => ({
            _id: id,
            start: `${this.formatDate(start)} ${selectedEvent.startTime}`,
            end: `${this.formatDate(end)} ${selectedEvent.endTime}`,
            previousDate: previousDate
          });

          return createEvent(evnt._id, newStartDateTime, newEndDateTime, evnt.previousDate);

        }).filter(event => event !== undefined);

        resolve(replyForIds)
      });
    },


    //------Requisições------------

    getEvents(params = {}) {
      return new Promise((resolve, reject) => {

        let startDate = new Date()
        let endDate = new Date()

        if (!this.actualViewForCalendar.startDate && !this.actualViewForCalendar.endDate) {
          let days = this.hideCalendar ? 90 : 30
          const today = new Date();
          startDate = new Date(today);
          endDate = new Date(today);
          startDate.setDate(today.getDate() - days);
          endDate.setDate(today.getDate() + days);

        } else {
          startDate = new Date(this.actualViewForCalendar.startDate);
          startDate.setHours(0, 0, 0, 0);
          endDate = new Date(this.actualViewForCalendar.endDate);
          endDate.setHours(0, 0, 0, 0);

        }

        let options = {
          method: 'GET',
          url: `https://${this.subdominio}.clictecnologia.com.br/api/agenda`,
          headers: { "content-type": "application/json" },
          params: {
            ...params,
            disableCompletedEvents: this.disableCompletedEvents,
            startDate: startDate,
            endDate: endDate
          }
        };

        try {
          this.$axios.request(options).then((response) => {
            if (response.data.metricas) { this.metrics = response.data.metricas }
            resolve(response.data.dados)
          });
        } catch (e) {
          resolve({ error: e })
        }
      });
    },

    postEvent(event, recurrent) {
      return new Promise(async (resolve, reject) => {

        let options = {
          method: 'POST',
          url: `https://${this.subdominio}.clictecnologia.com.br/api/agenda`,
          headers: { "content-type": "application/json" },
          data: {
            evento: event,
          }
        };

        if (recurrent) {
          if (!recurrent._id) {
            recurrent = await this.postRecurrent(recurrent);
          }
          options.data.recorrencia = recurrent
        }

        try {
          this.$axios.request(options).then((response) => {
            resolve();
          });
        } catch (e) {
          resolve({ error: e });
        }
      });
    },

    putEvent(event, replyForIds = []) {
      return new Promise((resolve, reject) => {

        let options = {
          method: 'PUT',
          url: `https://${this.subdominio}.clictecnologia.com.br/api/agenda`,
          headers: { "content-type": "application/json" },
          data: {
            evento: event,
            eventosRelacionados: replyForIds
          }
        };

        try {
          this.$axios.request(options).then((response) => {
            resolve()
          });
        } catch (e) {
          resolve({ error: e })
        }
      });
    },

    deleteEvent(idsToDelete) {
      return new Promise((resolve, reject) => {
        if (!idsToDelete) { idsToDelete = [{ "_id": this.selectedEvent._id }] }

        let options = {
          method: 'DELETE',
          url: `https://${this.subdominio}.clictecnologia.com.br/api/agenda`,
          headers: { "content-type": "application/json" },
          data: {
            "ids": idsToDelete
          }
        };

        try {
          this.$axios.request(options).then((response) => {
            if (response.data.codigo == 1) {
              this.setEvents();
              resolve();
            } else {
              this.$notificacao({ text: response.data.mensagem, type: 'danger' });
              resolve();
            }
          });
        } catch (e) {
          resolve({ error: e })
        }
      });
    },

    getRecurrent(params) {
      return new Promise((resolve, reject) => {
        let options = {
          method: 'GET',
          url: `https://${this.subdominio}.clictecnologia.com.br/api/recorrencia`,
          headers: { "content-type": "application/json" },
          params: { ...params }
        };

        try {
          this.$axios.request(options).then((response) => {
            resolve(response.data.dados)
          });
        } catch (e) {
          resolve({ error: e })
        }
      });
    },

    postRecurrent(recurrent) {

      if (!recurrent.startDate) {
        recurrent.startDate = this.selectedEvent.startDate
      }

      if (recurrent.newStartDate && recurrent.newEndDate) {
        recurrent.startDate = recurrent.newStartDate;
        recurrent.endDate = recurrent.newEndDate;
        delete recurrent.newStartDate;
        delete recurrent.newEndDate;
      }

      return new Promise((resolve, reject) => {
        let options = {
          method: 'POST',
          url: `https://${this.subdominio}.clictecnologia.com.br/api/recorrencia`,
          headers: { "content-type": "application/json" },
          data: {
            ...recurrent,
          }
        };

        try {
          this.$axios.request(options).then((response) => {
            const recurrent = response.data?.dados?.recorrencia ?? null;
            if (recurrent) {
              resolve(recurrent);
            } else {
              resolve();
            }
          });
        } catch (e) {
          resolve({ error: e })
        }
      });
    },

    deleteRecurrent(recurrentId) {
      return new Promise((resolve, reject) => {
        let options = {
          method: 'DELETE',
          url: `https://${this.subdominio}.clictecnologia.com.br/api/recorrencia`,
          headers: { "content-type": "application/json" },
          data: {
            "id": recurrentId
          }
        };

        try {
          this.$axios.request(options).then((response) => {
            resolve()
          });
        } catch (e) {
          resolve({ error: e })
        }
      });
    },


    //-------Funções auxiliares--------

    generateDate(date, time) {
      const timeParts = time.split(':');
      const dateParts = date.split('-');
      let eventDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);

      eventDate.setHours(parseInt(timeParts[0], 10));
      eventDate.setMinutes(parseInt(timeParts[1], 10));
      eventDate.setSeconds(0);

      return eventDate
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate
    },
    formatTime(date) {
      const formattedTime = new Date(date).toLocaleTimeString(this.locale, { timeZone: this.timeZone, hour: '2-digit', minute: '2-digit', hour12: false })

      return formattedTime
    },
    getTimeDifference() {
      const startDate = this.generateDate(this.selectedEvent.startDate, this.selectedEvent.startTime)
      const endDate = this.generateDate(this.selectedEvent.endDate, this.selectedEvent.endTime)
      const diffMinutes = (endDate - startDate) / (1000 * 60);
      return diffMinutes
    },
    formatDateForList(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const localDate = new Date(date + 'T00:00:00');
      return localDate.toLocaleDateString(this.locale, options);
    },
    formatDayOfWeek(date) {
      const options = { weekday: 'long' };
      return new Date(date + 'T00:00:00').toLocaleDateString(this.locale, options);
    },
    formatTimeForList(datetime, startDatetime) {
      const time = datetime.split(' ')[1];
      if (startDatetime) {
        const startDate = new Date(startDatetime);
        const endDate = new Date(datetime);
        const diffTime = Math.abs(endDate - startDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays > 1 ? `${time} +${diffDays - 1} Dia(s)` : time;
      }
      return time;
    },


    //---------Slot---------

    getSlotAcao() {
      let row = this.$getSlot('acao');
      let filteredRow = row.filter(element => element.component === 'ClicDropdown');
      return filteredRow;
    },
    getSlotModal() {
      let row = [{
        "id": "containerModalClicCalendar",
        "component": "ClicRow",
        "props": { "visible": true, "slot": "modal" },
        "events": {},
        "childrens": []
      }]
      row[0].childrens = this.$getSlot('modal');
      return row

    },
    getPersonalizedInputs() {
      let comp = this.$getComponents()
      if (comp.containerModalClicCalendar) {
        return comp.containerModalClicCalendar.getFormValuesAsJson();
      }
    },
    definePersonalizedInputs(objeto) {
      let comp = this.$getComponents()
      if (comp.containerModalClicCalendar) {
        comp.containerModalClicCalendar.setFormValues(objeto);
      }
    },
    clearPersonalizedInputs() {
      let comp = this.$getComponents()
      if (comp.containerModalClicCalendar) {
        comp.containerModalClicCalendar.clearForm();
      }
    },
    getSelectedEventSituation() {
      return this.selectedEvent?.situacao?.value ?? "CONCLUIDO"
    },
    getSituationIcon(value) {
      let filteredSituations = this.situations.filter((situation) => situation.value == value);
      if (filteredSituations[0]?.icon) {
        return filteredSituations[0].icon
      }
      return ("md-done-round")
    }

  }

};
</script>

<style>
.vuecal--month-view .vuecal__cell {
  min-height: 50px;
}

.vuecal--month-view .vuecal__cell-content {
  justify-content: flex-start;
  height: 100%;
  align-items: flex-end;
}

.vuecal--month-view .vuecal__cell-date {
  padding: 4px;
}

.vuecal--month-view .vuecal__no-event {
  display: none;
}


.vuecal__special-hours {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;

  em {
    font-size: 0.9em;
    color: #999;
  }
}


.color-block {
  width: 20px;
  /* Defina a largura do bloco de cor conforme necessário */
  height: 20px;
  /* Defina a altura do bloco de cor conforme necessário */
  background-color: #ffffff;
  /* Defina a cor desejada */
  margin-right: 8px;
  /* Espaço entre o bloco de cor e o select */
  border-radius: 3px;
  /* Opcional: arredondar os cantos */
}

.clic {
  background-color: #f68b2a;
  color: #ffffff;
}

.black {
  background-color: #000000;
  color: #ffffff;
}

.green {
  background-color: #01ad15;
  color: #eaeaea;
}

.blue {
  background-color: #d6e4fa;
  color: #0061ff;
}

.pink {
  background-color: #fcf0ff;
  color: #cf1fff;
}

.yellow {
  background-color: #fffbe5;
  color: #e6cf56;
}

.purple {
  background-color: #5500ff;
  color: #ffffff;
}

.red {
  background-color: #eb0000;
  color: #ffffff;
}

.gray {
  background-color: #f5f5f5;
  color: #808080;
}

.cyan {
  background-color: #077070;
  color: #ffffff;
}

.yellow {
  background-color: #ffffcc;
  color: #cccc33;
}

.brown {
  background-color: #f4a460;
  color: #fff8f3;
}

.indigo {
  background-color: #4b0082;
  color: #f9f0ff;
}

.olive {
  background-color: #808000;
  color: #eeeeee;
}




.closed {
  background:
    #fff7f0 repeating-linear-gradient(-45deg,
      rgba(255, 162, 87, 0.25),
      rgba(255, 162, 87, 0.25) 5px,
      rgba(255, 255, 255, 0) 5px,
      rgba(255, 255, 255, 0) 15px);
  color: #f6984c;
}
</style>
