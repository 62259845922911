<template>
  <ClicColInterno :cp="cp" :key="componentKey">
    <b-img :alt="alt" :blank="blank" :blank-color="blankColor" :block="block" :center="center" :fluid="fluid"
      :fluid-grow="fluidGrow" :height="height" :left="left" :right="right" :rounded="rounded" :sizes="sizes"
      :src="src ? src : fallbackSrc ? fallbackSrc : ''" :srcset="srcset" :thumbnail="thumbnail" :width="width"
      :style="style" :class="this.class" :title="title" @error="handleImageError">
    </b-img>
  </ClicColInterno>
</template>

<script>
import ComponentMixin from '../../core/ComponentMixin';

export default {
  name: 'ClicImage',
  mixins: [ComponentMixin],
  methods: {
    handleImageError(event) {
      if (this.fallbackSrc) {
        event.target.src = this.fallbackSrc
      }
    }
  }
};
</script>
